import React from "react";
//import css file
import "./index.scss";
import { productTypeImageObj } from "../../Constants/Constants";
import { ReactComponent as DownloadIcon } from "../../assets/images/common/download_file.svg";
import BookingCoin from "../../assets/lottie/bookingCoin.json";
import { InsurerLogoMap } from "../../Constants/InsurerLogoMap";
import LottiePlayer from "../../Components/LottiePlayer/LottiePlayer";
const BookingTable = ({ item, isLoading, handleClick }) => {
  return (
    <div className="sub-card-container">
      <div
        className="pos-rel cursor_pointer"
        onClick={() => handleClick(item.bookingId)}
      >
        <div className="card-header">
          <div className="vehicle-info">
            <div className="vehicle_image">
              {productTypeImageObj[item.product]}
            </div>
            <div className="vehicle-num-name">
              <h3>{item.registrationNumber}</h3>
              <p>
                {item.make} {item.model}
              </p>
            </div>
          </div>
          {/* <h3>{item.insurerName}</h3> */}
          <div className="booking-logo-container">
            {item.insurerId && InsurerLogoMap[item.insurerId] ? (
              <img
                src={InsurerLogoMap[item.insurerId]?.Logo}
                alt={item.insurerName}
                className="logo_image"
              />
            ) : (
              <div class="logo_name">{item.insurerName}</div>
            )}
          </div>
          <div className="coin-container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                borderRadius: "17px",
                border: "1px solid",
                borderColor: item.status === "BOOKED" ? "#32C053" : "#FF0000",
                minWidth: "62px",
                color: item.status === "BOOKED" ? "#32C053" : "#FF0000",
                height: "24px",
                // padding: "4px 8px",
                justifyContent: "space-between",
                paddingRight: "8px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "24px",
                  backgroundColor:
                    item.status === "BOOKED" ? "#32C053" : "#FF0000",
                  borderRadius: "50%",
                  height: "24px",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    transform: "translate(-28%, -27%)",
                  }}
                >
                  <LottiePlayer
                    animationData={BookingCoin}
                    height={52}
                    width={52}
                  />
                </div>
              </div>
              <p>0</p>
            </div>
          </div>
        </div>
        <div className="mt_24">
          <div
            className={`w-fit pos-abs ${
              item.status === "BOOKED"
                ? "booked"
                : item.status === "REJECTED"
                  ? "rejected"
                  : ""
            }`}
          >
            {item.status}
          </div>
        </div>
        <div className="card-content">
          <div className="card-content-info">
            <h3>Customer</h3>
            <p>{item.customerName}</p>
          </div>
          <div className="card-content-info booking-id">
            <h3>Booking Id</h3>
            <p>{item.bookingId}</p>
          </div>
          <div className="card-content-info">
            <h3>Premium</h3>
            <p>{item.premium || "-"}</p>
          </div>
        </div>
      </div>
      <div className="booking-card-footer">
        <DownloadIcon width={24} height={24} />

        <a href={item.policyCopyUrl} target="_blank" rel="noreferrer">
          Download PDF
        </a>
      </div>
    </div>
  );
};

export default BookingTable;
